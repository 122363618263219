<template>
  <div>
    <br />
    <span>
      <p class="text-primary">
        404: NotFound
      </p>
    </span>
    <router-link to="/">
      <input class="btn btn-primary btn-lg" type="submit" value="Home" />
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
